import { createReducer } from 'typesafe-actions';
import { SelectedDateYogaClassState, SelectedDateYogaClassAction } from './types';
import { UPDATE_SELECTEDDATEYOGACLASS } from './actions';
import dayjs from "dayjs";

const initialState: SelectedDateYogaClassState = {
    date: dayjs(),
    dateRange: [dayjs(), dayjs().add(1, 'month')],
    selectedPeriod: false,
    selectedYogaCenter: 0
};

const yogaDateYogaClass = createReducer<SelectedDateYogaClassState, SelectedDateYogaClassAction>(initialState, {
    [UPDATE_SELECTEDDATEYOGACLASS]: (state, action) => ({
        ...state,
        date: action.payload.date,
        selectedPeriod: action.payload.selectedPeriod,
        dateRange: action.payload.dateRange,
        selectedYogaCenter: action.payload.selectedYogaCenter
    })
});

export default yogaDateYogaClass;