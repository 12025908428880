import { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import { Button, Col, Layout, Menu, Row } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PageHistory from '../../components/PageHistory';
import { getLocalAccessToken, removeUser, validateToken } from '../../service/Token';
import { itemsMain } from './items';
import { ReactComponent as PositivehotelLogoFull } from '../../assets/images/positivehotelLogoFull.svg'
import Notification from '../../utils/firebaseNotifications/Notification';

const MainLayout: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const onClick: MenuProps['onClick'] = (clicked) => {
        setSelectedKey(clicked.key)
        navigate(clicked.key)
    };
    const token = getLocalAccessToken();

    useEffect(() => {
        if (!token || (token.length < 4)) { return navigate("/login"); }
        else { validateToken().then(() => setIsAdmin(true)).catch(() => { removeUser(); return navigate("/login"); }) }
    }, [])
    return (
        <Layout>
            <Layout.Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} theme='light'>
                <Row style={{ margin: "16px", textAlign: "center" }}>
                    <Col span={24} style={{ fontSize: 18 }}><PositivehotelLogoFull /></Col>
                </Row>
                <Menu selectedKeys={[selectedKey]} mode="inline" items={itemsMain} onClick={onClick} />
            </Layout.Sider>
            <Layout>
                <Layout.Header style={{ padding: 0, display: "flex", justifyContent: "end", alignItems: "center", paddingInline: "6%", gap: "24px", backgroundColor: 'white' }}>
                    {token && <><div>파지티브호텔 관리자 님</div><Button onClick={() => { removeUser(); navigate("/login", { replace: true }) }}>로그아웃</Button></>}
                </Layout.Header>
                <Layout.Content style={{ margin: '0 16px' }}>
                    <PageHistory />
                    <div style={{ backgroundColor: 'white', padding: '20px' }}>
                        {isAdmin && <>
                            <Outlet />
                            <Notification />
                        </>}
                    </div>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: 'center', padding: "16px", }}></Layout.Footer>
            </Layout>
        </Layout>
    );
};

export default MainLayout;