import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_RESERVATIONLIST = 'reservation/GET_RESERVATIONLIST';
export const GET_RESERVATIONLIST_SUCCESS = 'reservation/GET_RESERVATIONLIST_SUCCESS';
export const GET_RESERVATIONLIST_ERROR = 'reservation/GET_RESERVATIONLIST_ERROR';

export const getReservationListAsync = createAsyncAction(
    GET_RESERVATIONLIST,
    GET_RESERVATIONLIST_SUCCESS,
    GET_RESERVATIONLIST_ERROR
)<undefined, Array<IReservation>, AxiosError>();


