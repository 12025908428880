import { Button } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getNaverUserAdmin } from "../../../service/login";
import { useEffect } from "react";

const NaverLoginCallback = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    useEffect(() => {
        if (!code) {
            navigate('/naverlogin');
            return;
        }
        requestGetUser(code);
    }, [code]);
    const requestGetUser = async (code: string) => {
        await getNaverUserAdmin(code);
        navigate('/');
    }
    return <>
        <Button onClick={() => navigate('/')} > 홈페이지</Button>
        <Button onClick={() => navigate('/login')} > 로그인 페이지</Button>
    </>
}

export default NaverLoginCallback;