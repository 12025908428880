import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { CompanyAction } from './types';
import { getCompanyList } from '../../service/company';

import { getCompanyListAsync } from './actions';

export function getCompanyListThunk(): ThunkAction<void, RootState, null, CompanyAction> {
    return async dispatch => {
        const { request, success, failure } = getCompanyListAsync;
        dispatch(request());
        try {
            const companyList = await getCompanyList();
            dispatch(success(companyList.data));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};