import { Button, DatePicker, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import Login from "./ui/Login";
import { useEffect, useState } from "react";
import RoomTable from "./ui/RoomTable";
import MemberModal from "./ui/MemberModal";
import { getLightBarcodeList, updateActiveLightBarcodeList } from "../../service/lightBarcode";
import IlightBarcode from "./model/LightBarcodeType";

const MediaArt = () => {
    const [targetDate, setTargetDate] = useState<(Dayjs)>(dayjs());
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lightBarcodeList, setLightBarcodeList] = useState<IlightBarcode[]>([]);

    useEffect(() => {
        requestLightBarcodeList();
    }, [targetDate]);

    const onChange = (e: Dayjs) => {
        setTargetDate(e);
    }

    const requestLightBarcodeList = () => {
        getLightBarcodeList(targetDate.format("YYYY-MM-DDTHH:mm:ss"))
            .then(res => {
                setLightBarcodeList(res.data);
            }).catch(err => {
                console.log(err);
            })
    }

    const clickActiveButton = () => {
        if (lightBarcodeList.length === 0) {
            return;
        }
        updateActiveLightBarcodeList(targetDate.format("YYYY-MM-DDTHH:mm:ss"), !lightBarcodeList[0].isActive)
            .then((res) => {
                console.log('res', res);
                requestLightBarcodeList();
            }).catch(err => {
                console.log(err);
            });
    }

    return (
        <div>
            {!isLogin ? <Login setIsLogin={setIsLogin} />
                : <>
                    <div style={{ padding: '30px 50px' }}>
                        <DatePicker onChange={onChange} defaultValue={dayjs()} />
                        <div style={{ marginTop: 40 }}>
                            <RoomTable
                                lightBarcodeList={lightBarcodeList}
                                setSelectedId={(id) => {
                                    setSelectedId(id);
                                    setIsModalOpen(true);
                                }} />
                            <MemberModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} barcodeData={lightBarcodeList.find((lightBarcode) => lightBarcode.barcodeData.includes(selectedId.toString()))?.barcodeData} barcodeWithLightUrl={lightBarcodeList.find((lightBarcode) => lightBarcode.barcodeData.includes(selectedId.toString()))?.barcodeWithLightUrl} />
                            <br />
                            <Button onClick={clickActiveButton} disabled={lightBarcodeList.length === 0}>활성화</Button>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default MediaArt;