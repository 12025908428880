import React, { useState } from 'react';
import { Modal, Table } from 'antd';
import type { TableColumnsType } from 'antd';
import IlightBarcode from '../model/LightBarcodeType';


interface DataItem {
    id: number;
    name: string;
    barcodeData: string;
    barcodeWithLightUrl: string;
    startDateTime: string;
    endDateTime: string;
    isActive: boolean;
}

interface TransformedDataItem {
    key: string;
    name1?: string;
    name2?: string;
    name3?: string;
    name4?: string;
}

interface IProps {
    setSelectedId: (id: number) => void;
    lightBarcodeList: IlightBarcode[];
}

const RoomTable = ({ lightBarcodeList, setSelectedId }: IProps) => {

    const transformData = (data: DataItem[]): TransformedDataItem[] => {
        let transformedData = [];

        for (let i = 0; i < data.length; i += 4) {
            let chunk = data.slice(i, i + 4);

            let transformedChunk: any = { key: (i / 4 + 1).toString() };

            chunk.forEach((item, index) => {
                transformedChunk[`name${index + 1}`] = `${item.barcodeData.split('?')[1]}/${item.name}/${item.isActive ? 'T' : 'F'}`;
            });

            transformedData.push(transformedChunk);
        }

        return transformedData;
    }

    const columns: TableColumnsType<TransformedDataItem> = [
        {
            dataIndex: 'name1',
            render: (text, record, index) => {
                return text == null ? <></> :
                    <>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ color: text.split('/')[2] === 'T' ? '#52c41a' : '#ff4d4f' }}>{text.split('/')[0]}</div>
                            <a onClick={() => { setSelectedId(text.split('/')[0]) }}>{text.split('/')[1]}</a>
                        </div>
                    </>
            },

        },
        {
            dataIndex: 'name2',
            render: (text, record, index) => {
                return text == null ? <></> :
                    <>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ color: text.split('/')[2] === 'T' ? '#52c41a' : '#ff4d4f' }}>{text.split('/')[0]}</div>
                            <a onClick={() => { setSelectedId(text.split('/')[0]) }}>{text.split('/')[1]}</a>
                        </div>
                    </>
            },
        },
        {
            dataIndex: 'name3',
            render: (text, record, index) => {
                return text == null ? <></> :
                    <>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ color: text.split('/')[2] === 'T' ? '#52c41a' : '#ff4d4f' }}>{text.split('/')[0]}</div>
                            <a onClick={() => { setSelectedId(text.split('/')[0]) }}>{text.split('/')[1]}</a>
                        </div>
                    </>
            },
        },
        {
            dataIndex: 'name4',
            render: (text, record, index) => {
                return text == null ? <></> :
                    <>
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ color: text.split('/')[2] === 'T' ? '#52c41a' : '#ff4d4f' }}>{text.split('/')[0]}</div>
                            <a onClick={() => { setSelectedId(text.split('/')[0]) }}>{text.split('/')[1]}</a>
                        </div>
                    </>
            },
        },
    ];

    return (<>
        <Table
            showHeader={false}
            columns={columns}
            dataSource={transformData(lightBarcodeList)}
            scroll={{ x: 500 }} pagination={false} bordered />
    </>)
};

export default RoomTable;