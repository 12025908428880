import { useEffect } from 'react';
import { Form, Radio, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { getSelectedCenterAsync } from '../../modules/selectedCenter';
import { getCompanyListThunk } from '../../modules/company'
import ErrorComponent from '../Error';

const { Option } = Select;

interface IProps {
    onlyCompany?: boolean
}

const SettingCompanyYogaCenter = ({ onlyCompany }: IProps) => {
    const dispatch = useDispatch();
    const update = getSelectedCenterAsync;
    const { companyId, yogaCenterId } = useSelector((state: RootState) => state.selectedCenter);
    const { companyData, companyError } = useSelector((state: RootState) => state.company.companyList);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!companyData) {
            dispatch(getCompanyListThunk());
        }
        else {
            dispatch(update({
                companyId: localStorage.getItem("hotelyoga_companyId") ? Number(localStorage.getItem("hotelyoga_companyId")) : undefined,
                yogaCenterId: localStorage.getItem("hotelyoga_yogaCenterId") ? Number(localStorage.getItem("hotelyoga_yogaCenterId")) : undefined
            }))
            form.setFieldValue('company', localStorage.getItem("hotelyoga_companyId") ? Number(localStorage.getItem("hotelyoga_companyId")) : undefined)
        }
    }, []);

    if (companyError) return <ErrorComponent errorData={companyError} />

    const getYogaCenterListRadioForm = () => {
        if (!companyData) return <Form.Item label="요가원 선택"></Form.Item>
        if (!companyId) return <Form.Item label="요가원 선택">회사를 선택해주세요.</Form.Item>

        const selectedCompany = companyData.find((company: ICompanyData) => company.id === companyId);
        if (!selectedCompany) return <Form.Item label="요가원 선택">등록되지 않은 회사입니다.</Form.Item>
        if (selectedCompany.yogaCenterList.length === 0) return <Form.Item label="요가원 선택">등록된 요가원이 없습니다.</Form.Item>

        return <Form.Item label="요가원 선택" rules={[{ required: true, message: '필수 입력사항입니다.' }]}>
            <Radio.Group
                value={yogaCenterId}
                onChange={(e) => {
                    dispatch(update({ companyId: companyId, yogaCenterId: e.target.value }))
                    localStorage.setItem("hotelyoga_yogaCenterId", e.target.value.toString());
                }}>
                {selectedCompany.yogaCenterList.map((yogaCenter: IYogaCenter) =>
                    <Radio key={yogaCenter.id} value={yogaCenter.id}>{yogaCenter.name}</Radio>
                )}
            </Radio.Group>
        </Form.Item>
    }

    return <>
        <Form
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 700 }}
        >
            <Form.Item name="company" label="회사 선택" rules={[{ required: true, message: '필수 입력사항입니다.' }]} initialValue={companyId}>
                <Select
                    placeholder="회사를 선택해주세요."
                    onChange={(e) => {
                        dispatch(update({ companyId: e, yogaCenterId: undefined }))
                        localStorage.removeItem("hotelyoga_yogaCenterId");
                        e ? localStorage.setItem("hotelyoga_companyId", e.toString()) : localStorage.removeItem("hotelyoga_companyId");
                    }}
                    allowClear
                >
                    {companyData && companyData.map((company: ICompanyData) => {
                        return <Option key={company.id} value={company.id}>{company.name}</Option>
                    })}
                </Select>
            </Form.Item>
            {!onlyCompany && getYogaCenterListRadioForm()}
        </Form >
    </>
}

export default SettingCompanyYogaCenter;