import { Button } from "antd";
import { getLocalAccessToken, removeUser } from "../../service/Token";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getCompanyById } from "../../service/company";
import { get } from "http";
import { getMemberList, getMemberListByRole } from "../../service/member";

const Login = () => {
    const KAKAO_AUTH_URL = `kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_LOGIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_LOGIN_REDIRECT_URI}&response_type=code&lang=ko&scope=phone_number,birthday,birthyear,gender,profile,account_email,name`;
    const NAVER_AUTH_URL = `nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_LOGIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_LOGIN_REDIRECT_URI}&state=${process.env.REACT_APP_NAVER_LOGIN_STATE}&lang=ko_KR`;

    const navigate = useNavigate();
    const clickKakaoLogin = () => {
        window.location.href = "https://" + KAKAO_AUTH_URL;
    }
    const clickNaverLogin = () => {
        window.location.href = "//" + NAVER_AUTH_URL;
    }
    useEffect(() => {
        // getMemberListByRole('ROLE_ADMIN')
        // getMemberList()
        // if (getLocalAccessToken()) getCompanyById("1");
    }, [])

    return <>
        <div style={{ width: "500px" }}>
            {getLocalAccessToken() ?
                <Button onClick={() => { removeUser(); navigate("/login", { replace: true }) }}>로그아웃</Button>
                : <>
                    <Button style={{ width: "40%" }} onClick={clickKakaoLogin} icon={<img style={{ width: "100%" }} src='https://img.positivehotel.io/oauth/kakao/kakao_login_medium_narrow.png' alt='카카오 로그인' />} />
                    <Button style={{ width: "40%" }} onClick={clickNaverLogin} icon={<img style={{ width: "100%" }} src='https://img.positivehotel.io/oauth/naver/login.png' alt='네이버 로그인' />} />
                </>}
        </div>
    </>
}
export default Login;