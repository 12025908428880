import { Button } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../modules";
import SettingCompanyYogaCenter from "../../components/SettingCompanyYogaCenter";

const CheckIn = () => {
    const navigate = useNavigate();
    const { companyId, yogaCenterId } = useSelector((state: RootState) => state.selectedCenter);


    return <>
        <SettingCompanyYogaCenter />
        <div style={{ display: "flex", gap: "30px", marginLeft: "148px" }}>
            <Button disabled={!(companyId && yogaCenterId)} onClick={() => navigate(`/check-in/screen/${companyId}/${yogaCenterId}`)}>전화번호 체크인</Button>
            <Button type="primary" disabled={!(companyId && yogaCenterId)} onClick={() => navigate(`/check-in/barcode/${companyId}/${yogaCenterId}`)}>바코드 체크인</Button>
        </div>
    </>
}
export default CheckIn;