import { AxiosResponse } from 'axios';
import api from './Api'


export const getKakaoUserAdmin = (code: string): Promise<AxiosResponse<any, any>> => {
    return api.post('/auth/admin/kakao', { authorizationCode: code })
}

export const getNaverUserAdmin = (code: string): Promise<AxiosResponse<any, any>> => {
    return api.post('/auth/admin/naver', { authorizationCode: code })
}
export const getRefreshTokenTest = (): Promise<AxiosResponse<any, any>> => {
    return api.get('/auth/test');
}