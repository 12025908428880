import CheckInLayout from "../layout/CheckInLayout";
import Loadable from '../components/Loadable';
import { lazy } from "react";


const CheckInScreen = Loadable(lazy(() => import('../pages/checkInScreen')));
const TicketSelectCheckIn = Loadable(lazy(() => import('../pages/checkInScreen/checkInScreenByMember')));

const CheckInScreenRouter = {
    path: '/check-in/screen',
    element: <CheckInLayout />,
    children: [
        { path: '/check-in/screen/:companyId/:yogaCenterId', element: <CheckInScreen /> },
        { path: '/check-in/screen/:companyId/:yogaCenterId/:memberId', element: <TicketSelectCheckIn /> }
    ]
}

export default CheckInScreenRouter;