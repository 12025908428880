import React from 'react';
import { Outlet } from 'react-router-dom';

const CheckInLayout: React.FC = () => {
    return (<div>
        <Outlet />
    </div>
    );
};

export default CheckInLayout;