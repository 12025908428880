import { Navigate, useRoutes } from 'react-router-dom';

import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import CheckInScreenRouter from './CheckInScreenRouter';
import MediaArt from '../pages/mediaArt';

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, LoginRoutes, CheckInScreenRouter,
        { path: '/media/art', element: <MediaArt /> },
        { path: '*', element: <Navigate to={"/login"} replace /> }]);
}
