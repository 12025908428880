import { combineReducers } from 'redux';
import company from './company'
import member from './member'
import selectedCenter from './selectedCenter';
import selectedDateYogaClass from './selectedDateYogaClass';
import reservation from './reservation'

const rootReducer = combineReducers({
    company,
    selectedCenter,
    member,
    reservation,
    selectedDateYogaClass
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;