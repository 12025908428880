import { AxiosResponse } from "axios";
import api from './Api'

export const getLocalAccessToken = () => {
    const accessToken = localStorage.getItem("positivehotel-yoga-client-accessToken");
    if (accessToken === 'undefined' || !accessToken) return null;
    return accessToken;
};
export const updateLocalAccessToken = (newAccessToken: string) => {
    const accessToken = localStorage.getItem("positivehotel-yoga-client-accessToken");
    if (accessToken === 'undefined' || !accessToken) return setUser(newAccessToken);
    localStorage.setItem("positivehotel-yoga-client-accessToken", newAccessToken);
};
export const setUser = (accessToken: string) => {
    localStorage.setItem("positivehotel-yoga-client-accessToken", accessToken);
};
export const removeUser = () => {
    localStorage.removeItem("positivehotel-yoga-client-accessToken");
};

export const validateToken = (): Promise<AxiosResponse<IMember>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/jwt`)
}