import { AxiosResponse } from 'axios';
import api from './Api'

export const getCompanyById = (id: string): Promise<AxiosResponse<ICompanyData, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/company/${id}`)
}
export const getCompanyList = (): Promise<AxiosResponse<Array<ICompanyData>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/company`)
}
export const updateCompany = (companytion: ICompanyData) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/company`, companytion)
}
export const createCompany = (companytion: ICompanyData) => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/company`, companytion);
}
export const deleteCompany = (idList: Array<number>): Promise<string> => {
    return api.delete(`${process.env.REACT_APP_API_DOMAIN}/company`, { data: { idList: idList } })
};