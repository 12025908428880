import { createReducer } from 'typesafe-actions';
import { CompanyState, CompanyAction } from './types';
import {
    GET_COMPANYLIST,
    GET_COMPANYLIST_SUCCESS,
    GET_COMPANYLIST_ERROR
} from './actions';


const initialState: CompanyState = {
    companyList: {
        companyLoading: false,
        companyError: null,
        companyData: null
    }
};

const company = createReducer<CompanyState, CompanyAction>(initialState, {
    [GET_COMPANYLIST]: state => ({
        ...state,
        companyList: {
            companyLoading: true,
            companyError: null,
            companyData: null
        }
    }),
    [GET_COMPANYLIST_SUCCESS]: (state, action) => ({
        ...state,
        companyList: {
            companyLoading: false,
            companyError: null,
            companyData: action.payload
        }
    }),
    [GET_COMPANYLIST_ERROR]: (state, action) => ({
        ...state,
        companyList: {
            companyLoading: false,
            companyError: action.payload,
            companyData: null
        }
    }),
});

export default company;