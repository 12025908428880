import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_COMPANYLIST = 'company/GET_COMPANYLIST';
export const GET_COMPANYLIST_SUCCESS = 'company/GET_COMPANYLIST_SUCCESS';
export const GET_COMPANYLIST_ERROR = 'company/GET_COMPANYLIST_ERROR';

export const getCompanyListAsync = createAsyncAction(
    GET_COMPANYLIST,
    GET_COMPANYLIST_SUCCESS,
    GET_COMPANYLIST_ERROR
)<undefined, Array<ICompanyData>, AxiosError>();


