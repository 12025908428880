import { createAsyncAction } from 'typesafe-actions';
import { AxiosError } from 'axios';

export const GET_MEMBERLIST = 'member/GET_MEMBERLIST';
export const GET_MEMBERLIST_SUCCESS = 'member/GET_MEMBERLIST_SUCCESS';
export const GET_MEMBERLIST_ERROR = 'member/GET_MEMBERLIST_ERROR';

export const getMemberListAsync = createAsyncAction(
    GET_MEMBERLIST,
    GET_MEMBERLIST_SUCCESS,
    GET_MEMBERLIST_ERROR
)<undefined, Array<IMember>, AxiosError>();


