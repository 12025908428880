import { createReducer } from 'typesafe-actions';
import { SelectedCenterState, SelectedCenterAction } from './types';
import { UPDATE_SELECTEDCENTER } from './actions';


const initialState: SelectedCenterState = {
    companyId: 1,
    yogaCenterId: undefined,
};

const yogaCenter = createReducer<SelectedCenterState, SelectedCenterAction>(initialState, {
    [UPDATE_SELECTEDCENTER]: (state, action) => ({
        ...state,
        companyId: action.payload.companyId,
        yogaCenterId: action.payload.yogaCenterId,
    })
});

export default yogaCenter;