import { Breadcrumb } from 'antd';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
interface IBreadCrumbItem {
    title: ReactNode | string;
}
const breadCrumbNameMap: Record<string, Array<IBreadCrumbItem>> = {
    '/': [{ title: '일정' }],
    '/schedule': [{ title: '일정' }],
    '/week': [{ title: '웰니스 Week' }],
    '/week/create': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: '웰니스 Week 생성' }],
    '/week/detail': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: '웰니스 Week 상세' }],
    '/item': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: '웰니스 Week Item' }],
    '/item/create': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: <Link to={'/item'}>웰니스 Week Item</Link> }, { title: '웰니스 Week Item 생성' }],
    '/item/detail': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: <Link to={'/item'}>웰니스 Week Item</Link> }, { title: '웰니스 Week Item 수정' }],
    '/reservation': [{ title: '예약' }],
    '/reservation/create': [{ title: <Link to={'/reservation'}>예약</Link> }, { title: '예약 생성' }],
    '/check-in/history': [{ title: '출석 내역' }],
    '/yoga-class': [{ title: '수업' }],
    '/yoga-lecture/detail': [{ title: <Link to={'/yoga-class'}>수업</Link> }, { title: '수업 상세' }],
    '/yoga-lecture/update/detail': [{ title: <Link to={'/yoga-class'}>수업</Link> }, { title: '수업 수정' }],
    '/yoga-class/detail': [{ title: <Link to={'/yoga-class'}>수업</Link> }, { title: '그룹 수업 상세' }],
    '/yoga-class/update/detail': [{ title: <Link to={'/yoga-class'}>수업</Link> }, { title: '그룹 수업 수정' }],
    '/member': [{ title: '회원' }],
    '/member/create': [{ title: <Link to={'/member'}>회원</Link> }, { title: '회원 등록' },],
    '/member/detail': [{ title: <Link to={'/member'}>회원</Link> }, { title: '회원 상세' },],
    '/teacher': [{ title: '코치' }],
    '/teacher/create': [{ title: <Link to={'/teacher'}>코치</Link> }, { title: '코치 등록' }],
    '/teacher/detail': [{ title: <Link to={'/teacher'}>코치</Link> }, { title: '코치 수정' }],
    '/ticket': [{ title: '정기권' }],
    '/ticket/create': [{ title: <Link to={'/ticket'}>정기권</Link> }, { title: '정기권 등록' }],
    '/ticket/detail': [{ title: <Link to={'/ticket'}>정기권</Link> }, { title: '정기권 상세' }],
    '/ticket/update/detail': [{ title: <Link to={'/ticket'}>정기권</Link> }, { title: '정기권 수정' }],
    '/coupon': [{ title: '쿠폰' }],
    '/coupon/create': [{ title: <Link to={'/coupon'}>쿠폰</Link> }, { title: '쿠폰 등록' }],
    '/coupon/detail': [{ title: <Link to={'/coupon'}>쿠폰</Link> }, { title: '쿠폰 수정' }],
    '/yoga-coupon/detail': [{ title: <Link to={'/coupon'}>쿠폰</Link> }, { title: '요가 쿠폰 수정' }],
    '/notification/create': [{ title: '알림' }, { title: '푸시 알림 발송' }],
    '/notification': [{ title: '알림' }, { title: '발송 완료함' }],
    '/notification/push/detail': [{ title: '알림' }, { title: <Link to={'/notification'}>발송 완료함</Link> }, { title: '발송 완료 상세' }],
    '/notification/reservation': [{ title: '알림' }, { title: '예약 발송함' }],
    '/notification/reservation/detail': [{ title: '알림' }, { title: <Link to={'/notification/reservation'}>예약 발송함</Link> }, { title: '예약 발송 상세' }],
    '/notification/temp': [{ title: '알림' }, { title: '임시 저장함' }],
    '/notification/temp/detail': [{ title: '알림' }, { title: <Link to={'/notification/temp'}>임시 저장함</Link> }, { title: '임시 저장 상세' }],
    '/company': [{ title: '회사' }],
    '/company/detail/detail': [{ title: <Link to={'/company'}>회사</Link> }, { title: '회사 상세' }],
    '/setting/basic': [{ title: '설정' }, { title: '기본 정보' }],
    '/setting/detail': [{ title: '설정' }, { title: '상세 정보' }],
    '/setting/image-management': [{ title: '설정' }, { title: '요가원 사진 등록' }],
    '/setting/schedule': [{ title: '설정' }, { title: '요가원 시간표' }],
    '/setting/notice-popup': [{ title: '설정' }, { title: '공지사항' }],
    '/setting/notice-popup/create': [{ title: '설정' }, { title: <Link to={'/setting/notice-popup'}>공지사항</Link> }, { title: '공지사항 등록' }],
    '/setting/notice-popup/detail': [{ title: '설정' }, { title: <Link to={'/setting/notice-popup'}>공지사항</Link> }, { title: '공지사항 상세' }],
    '/setting/event': [{ title: '설정' }, { title: '이벤트' }],
    '/setting/event/create': [{ title: '설정' }, { title: <Link to={'/setting/event'}>이벤트</Link> }, { title: '이벤트 등록' }],
    '/setting/event/detail': [{ title: '설정' }, { title: <Link to={'/setting/event'}>이벤트</Link> }, { title: '이벤트 상세' }],
    '/setting/banner': [{ title: '설정' }, { title: '배너' }],
    '/setting/banner/create': [{ title: '설정' }, { title: <Link to={'/setting/banner'}>배너</Link> }, { title: '배너 등록' }],
    '/setting/banner/detail': [{ title: '설정' }, { title: <Link to={'/setting/banner'}>배너</Link> }, { title: '배너 수정' }],
    '/revenue': [{ title: '매출' }],
    '/data-escalation': [{ title: '데이터 이관' }],
    '/check-in': [{ title: '출석' }],
    '/check-in/barcode/1/detail': [{ title: '바코드 출석' }],
    '/login': [{ title: '로그인' }],


}

const PageHistory = () => {
    const location = useLocation();
    const pathSnippets = location.pathname;
    const checkDynamicPath = ((path: string) => {
        const regex = /\d/;
        if (regex.test(path)) {
            return path.split('/').slice(0, -1).join('/') + '/detail'
        } else {
            return path
        }
    })
    return (
        <Breadcrumb style={{ margin: '16px 0' }} items={breadCrumbNameMap[checkDynamicPath(pathSnippets)]} />
    )
}
export default PageHistory;