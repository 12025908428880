import { AxiosResponse } from 'axios';
import api from './Api'

export const getReservationById = (id: string): Promise<IDetailReservation> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/reservation/admin/detail/${id}`)
        .then((res) => res.data)
        .catch((error) => console.error(error));
}
export const getReservationList = (): Promise<AxiosResponse<Array<IReservation>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/reservation`)
}
export const updateReservation = (reservation: IReservation) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/reservation`, reservation)
}
export const createReservation = (reservation: ICreateReservation) => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/reservation`, reservation);
}
export const deleteReservation = (id: number, isSend: boolean): Promise<string> => {
    return api.delete(`${process.env.REACT_APP_API_DOMAIN}/reservation/${id}?isSend=${isSend}`)
};
export const getSearchReservationList = (searchValue: string, startDate: string, endDate: string): Promise<AxiosResponse<Array<IReservation>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/reservation?search=${searchValue}&startDate=${startDate}&endDate=${endDate}`)
}
export const updateReservationOnCheckIn = (reservation: IUpdateReservationForCheckIn) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/reservation`, reservation)
}
export const getReservationListByMember = (memberId: string, startDateTime: string, endDateTime: string, status: string): Promise<AxiosResponse<Array<IReservationByMember>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/reservation/member?memberId=${memberId}&startDateTime=${startDateTime}&endDateTime=${endDateTime}&status=${status}`)
}