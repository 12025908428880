import { BankOutlined, BellOutlined, CalendarOutlined, CheckOutlined, ClockCircleOutlined, CreditCardOutlined, FileOutlined, FireOutlined, LoginOutlined, MoneyCollectOutlined, SettingOutlined, SmileOutlined, SolutionOutlined, TeamOutlined, } from '@ant-design/icons';
import type { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}
export const itemsMain: MenuItem[] = [
    getItem('일정', '/schedule', <CalendarOutlined />),
    getItem('라운지-Week', '/week', <SmileOutlined />),
    getItem('예약', '/reservation', <ClockCircleOutlined />),
    getItem('출석내역', '/check-in/history', <LoginOutlined />),
    getItem('수업', '/yoga-class', <FireOutlined />),
    getItem('회원', '/member', <TeamOutlined />),
    getItem('코치님', '/teacher', <SolutionOutlined />),
    getItem('정기권', '/ticket', <CreditCardOutlined />),
    getItem('쿠폰', '/coupon', <CreditCardOutlined />),
    getItem('알림', '#sub10', <BellOutlined />, [
        getItem('푸시 알림 발송', '/notification/create'),
        getItem('발송 완료함', '/notification'),
        getItem('예약 발송함', '/notification/reservation'),
        getItem('임시 저장함', '/notification/temp'),
    ]),

    getItem('회사 관리', '/company', <BankOutlined />),
    getItem('설정', '#sub12', <SettingOutlined />, [
        getItem('기본 정보', '/setting/basic'),
        getItem('상세 정보', '/setting/detail'),
        getItem('요가원 사진 등록', '/setting/image-management'),
        getItem('시간표', '/setting/schedule'),
        getItem('공지사항', '/setting/notice-popup'),
        getItem('이벤트', '/setting/event'),
        getItem('배너', '/setting/banner'),
    ]),
    getItem('매출', '/revenue', <CreditCardOutlined />),
    getItem('데이터 이관', '/data-escalation', <FileOutlined />),
    getItem('출석', '/check-in', <CheckOutlined />),

    getItem('로그인', '/login', <LoginOutlined />)
];