// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDtmXES7XgDhrqV10NImHiI8ozaBHKrhak",
    authDomain: "positive-hotel.firebaseapp.com",
    projectId: "positive-hotel",
    storageBucket: "positive-hotel.appspot.com",
    messagingSenderId: "892350695011",
    appId: "1:892350695011:web:74a3d742a2807bee079a56",
    measurementId: "G-JC3FRFX79W"
};
initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: `BM3QM3VLFzIN3HMEB342gvmPsPNQx-zfAWx1cycHNC1ErbP95HIRq6Bq81s4s9L4amI_hyOARydQu6UAMqMLVTA` })
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            return currentToken;

            // Perform any other neccessary action with the token
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
        }
    } catch (err: any) {
        console.error('An error occurred while retrieving token. ', err);
    }
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });