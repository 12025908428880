import { Modal } from "antd";
import { useState } from "react";

interface IProps {
    isModalOpen: boolean;
    setIsModalOpen: Function;
    barcodeWithLightUrl: string | undefined;
    barcodeData: string | undefined;
}

const MemberModal = ({ isModalOpen, setIsModalOpen, barcodeWithLightUrl, barcodeData }: IProps) => {

    return <Modal title="회원 정보" open={isModalOpen}
        footer={false}
        onCancel={() => setIsModalOpen(false)}>
        <img style={{ width: "100%" }} src={barcodeWithLightUrl} />
        <br />
        <div>바코드 정보 : {barcodeData}</div>
    </Modal>
}
export default MemberModal;