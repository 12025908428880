import { createReducer } from 'typesafe-actions';
import { MemberState, MemberAction } from './types';
import {
    GET_MEMBERLIST,
    GET_MEMBERLIST_SUCCESS,
    GET_MEMBERLIST_ERROR
} from './actions';


const initialState: MemberState = {
    memberList: {
        memberLoading: false,
        memberError: null,
        memberDataList: null
    }
};

const member = createReducer<MemberState, MemberAction>(initialState, {
    [GET_MEMBERLIST]: state => ({
        ...state,
        memberList: {
            memberLoading: true,
            memberError: null,
            memberDataList: null
        }
    }),
    [GET_MEMBERLIST_SUCCESS]: (state, action) => ({
        ...state,
        memberList: {
            memberLoading: false,
            memberError: null,
            memberDataList: action.payload
        }
    }),
    [GET_MEMBERLIST_ERROR]: (state, action) => ({
        ...state,
        memberList: {
            memberLoading: false,
            memberError: action.payload,
            memberDataList: null
        }
    }),
});

export default member;