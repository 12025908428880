import { createReducer } from 'typesafe-actions';
import { ReservationState, ReservationAction } from './types';
import {
    GET_RESERVATIONLIST,
    GET_RESERVATIONLIST_SUCCESS,
    GET_RESERVATIONLIST_ERROR
} from './actions';


const initialState: ReservationState = {
    reservationList: {
        reservationLoading: false,
        reservationError: null,
        reservationDataList: null
    }
};

const reservation = createReducer<ReservationState, ReservationAction>(initialState, {
    [GET_RESERVATIONLIST]: state => ({
        ...state,
        reservationList: {
            reservationLoading: true,
            reservationError: null,
            reservationDataList: null
        }
    }),
    [GET_RESERVATIONLIST_SUCCESS]: (state, action) => ({
        ...state,
        reservationList: {
            reservationLoading: false,
            reservationError: null,
            reservationDataList: action.payload
        }
    }),
    [GET_RESERVATIONLIST_ERROR]: (state, action) => ({
        ...state,
        reservationList: {
            reservationLoading: false,
            reservationError: action.payload,
            reservationDataList: null
        }
    }),
});

export default reservation;