import { ConfigProvider } from 'antd';
import './App.css'
import Routes from './routes'
const App = () => {
  return (
    <ConfigProvider theme={{ cssVar: true }}>
      <Routes />
    </ConfigProvider>
  );
};

export default App;