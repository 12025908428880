import axios from "axios";
import { getLocalAccessToken, setUser } from "./Token";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: true
});

instance.interceptors.request.use((config: any) => {
    const token = getLocalAccessToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use(
    (res) => {
        const accessToken = res.headers["authorization"];
        if (accessToken) {
            //재발급된 상태
            setUser(accessToken)
        }
        return res;
    },
    async (err) => {
        if (!err.response) {
            console.error("response is not found.  err: ", err);
            return Promise.reject(err);
        }
        if (err.response.status === 401) {
            if (window.confirm('Admin 권한이 없습니다. 관리자에게 문의해주세요.')) {
                window.location.href = "/login";
            } else {
                return Promise.reject(err);
            }
        } else if (err.response?.status && err.response?.status !== 401) {
            console.error(err.response)
            return Promise.reject(err);
        }
        return Promise.reject(err);
    }
);
export default instance;