import { useState, useEffect } from 'react'
import { requestForToken, onMessageListener } from './firebase';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import { Card, Modal, notification } from 'antd';
import { createAdminFcmTokenByToken } from '../../service/adminFcmToken';

const Notification = () => {
    let audio = new Audio('/bell-sound.mp3')
    const [messageFromFcm, setMessageFromFcm] = useState({ title: '', body: '' });
    const [api, contextHolder] = notification.useNotification();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [fcmToken, setFcmToken] = useState<string>('');

    const playSound = () => {
        audio.play();
    }

    const openNotification = (placement: NotificationPlacement) => {
        api.info({
            message: `체크인 알림`,
            description: <>
                <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{messageFromFcm.title}</div>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    {messageFromFcm.body}
                </div>
            </>,
            placement,
        });
    };

    useEffect(() => {
        audio.load();
        requestForToken().then((currentToken) => {
            if (currentToken) {
                setIsModalOpen(true)
                setFcmToken(currentToken);
            }
        })
    }, [])

    const requestAdminFcmToken = async (yogaCenterId: number) => {
        try {
            const res = await createAdminFcmTokenByToken({ id: 0, yogaCenterId, token: fcmToken })
        } catch (err) {
            console.error('error', err)
        }
        setIsModalOpen(false);
    }

    useEffect(() => {
        if (messageFromFcm?.title) {
            // notify()
            openNotification('top');
            playSound();

        }
    }, [messageFromFcm])



    onMessageListener()
        .then((payload: any) => {
            setMessageFromFcm({ title: payload?.notification?.title, body: payload?.notification?.body });
        })
        .catch((err) => console.error('failed: ', err));

    return (
        // <Toaster/>
        <>
            {contextHolder}
            <Modal title="체크인 알림 센터 선택" width="800px" styles={{ body: { height: "160px" } }} open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)} footer={false}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '25%' }}>
                        <Card onClick={() => requestAdminFcmToken(3)} style={{ cursor: 'pointer', height: '150px' }} styles={{ body: { padding: '10px' } }}>
                            <div>
                                <img src={'https://img.positivehotel.io/2023/12/8/%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%86%E1%85%A7%E1%86%AB1.jpg'} alt='선릉 이미지' style={{ width: '100%' }} />
                            </div>
                            <div>선릉점</div>
                        </Card>
                    </div>
                    <div style={{ marginLeft: '10px', width: '25%' }}>
                        <Card onClick={() => requestAdminFcmToken(2)} style={{ cursor: 'pointer', height: '150px' }} styles={{ body: { padding: '10px' } }}>
                            <div>
                                <img src={'https://img.positivehotel.io/2023/12/8/PHB09250.jpg'} alt='선릉 이미지' style={{ width: '100%' }} />
                            </div>
                            <div>도산점</div>
                        </Card>
                    </div>
                    <div style={{ marginLeft: '10px', width: '25%' }}>
                        <Card onClick={() => requestAdminFcmToken(1)} style={{ cursor: 'pointer', height: '150px' }} styles={{ body: { padding: '10px' } }}>
                            <div>
                                <img src={'https://img.positivehotel.io/2023/11/27/download%20%285%29.jpeg'} alt='선릉 이미지' style={{ width: '100%' }} />
                            </div>
                            <div>강남점</div>
                        </Card>
                    </div>
                    <div style={{ marginLeft: '10px', width: '25%' }}>
                        <Card onClick={() => requestAdminFcmToken(0)} style={{ cursor: 'pointer', height: '150px' }} styles={{ body: { padding: '10px' } }}>

                            <div style={{ textAlign: 'center' }}>알림 없음</div>
                        </Card>
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default Notification