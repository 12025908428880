import { lazy } from 'react';

import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';


const Member = Loadable(lazy(() => import('../pages/member')));
const MemberDetail = Loadable(lazy(() => import('../pages/member/detail')));
const CreateMember = Loadable(lazy(() => import('../pages/member/create')));
// const MemberAnalysis = Loadable(lazy(() => import('../pages/member/analysis')));

const Teacher = Loadable(lazy(() => import('../pages/teacher')));
const CreateTeacher = Loadable(lazy(() => import('../pages/teacher/create')));
const DetailTeacher = Loadable(lazy(() => import('../pages/teacher/detail')));

const Ticket = Loadable(lazy(() => import('../pages/ticket')));
const CreateTicket = Loadable(lazy(() => import('../pages/ticket/create')));
const UpdateTicket = Loadable(lazy(() => import('../pages/ticket/update')));
const TicketDetail = Loadable(lazy(() => import('../pages/ticket/detail')));

const SettingBasic = Loadable(lazy(() => import('../pages/setting/basic')));
const SettingDetail = Loadable(lazy(() => import('../pages/setting/detail')));
const NoticePopup = Loadable(lazy(() => import('../pages/setting/noticePopup')));
const CreateNoticePopup = Loadable(lazy(() => import('../pages/setting/noticePopup/create')));
const DetailNoticePopup = Loadable(lazy(() => import('../pages/setting/noticePopup/detail')));
const Banner = Loadable(lazy(() => import('../pages/setting/banner')));
const BannerCreate = Loadable(lazy(() => import('../pages/setting/banner/create')));
const BannerDetail = Loadable(lazy(() => import('../pages/setting/banner/detail')));
const ImageManagement = Loadable(lazy(() => import('../pages/setting/imageManagement')));
const SettingSchedule = Loadable(lazy(() => import('../pages/setting/schedule')));
const SettingEvent = Loadable(lazy(() => import('../pages/setting/event')));
const CreateEvent = Loadable(lazy(() => import('../pages/setting/event/create')));
const DetailEvent = Loadable(lazy(() => import('../pages/setting/event/detail')));


const Reservation = Loadable(lazy(() => import('../pages/reservation')));
const CreateReservation = Loadable(lazy(() => import('../pages/reservation/create')));
const DetailReservation = Loadable(lazy(() => import('../pages/reservation/detail')));

const Schedule = Loadable(lazy(() => import('../pages/schedule')));

// const DataEscalation = Loadable(lazy(() => import('../pages/dataEscalation')));

const YogaClass = Loadable(lazy(() => import('../pages/yogaClass')));
const CreateYogaClass = Loadable(lazy(() => import('../pages/yogaClass/create')));
const DetailYogaClass = Loadable(lazy(() => import('../pages/yogaClass/detail')));
const UpdateYogaClass = Loadable(lazy(() => import('../pages/yogaClass/update')));

const YogaLectureDetail = Loadable(lazy(() => import('../pages/yogaLecture/detail')));
const YogaLectureUpdate = Loadable(lazy(() => import('../pages/yogaLecture/update')));

// const TicketRevenue = Loadable(lazy(() => import('../pages/revenue/ticketRevenue')));
// const TicketRevenueAnalysis = Loadable(lazy(() => import('../pages/revenue/analysis')));

// const CheckIn = Loadable(lazy(() => import('../pages/checkIn')));
const CheckInHistory = Loadable(lazy(() => import('../pages/checkInHistory')));

const Coupon = Loadable(lazy(() => import('../pages/coupon')));
const CreateCoupon = Loadable(lazy(() => import('../pages/coupon/create')));
const UpdateCoupon = Loadable(lazy(() => import('../pages/coupon/update')));
const UpdateYogaCoupon = Loadable(lazy(() => import('../pages/yogaCoupon/update')));

const Notification = Loadable(lazy(() => import('../pages/notification')));
const CreateNotification = Loadable(lazy(() => import('../pages/notification/create')));
const NotificationReservation = Loadable(lazy(() => import('../pages/notification/reservation')));
const NotificationTemp = Loadable(lazy(() => import('../pages/notification/temp')));
const DetailPushNotification = Loadable(lazy(() => import('../pages/notification/push/detail')));
const DetailNotificationReservcation = Loadable(lazy(() => import('../pages/notification/reservation/detail')));
const DetailNotificationTemp = Loadable(lazy(() => import('../pages/notification/temp/detail')));


const Company = Loadable(lazy(() => import('../pages/company')));
const CompanyDetail = Loadable(lazy(() => import('../pages/company/detail')));

// const Login = Loadable(lazy(() => import('../pages/login')));

const DataEscalation = Loadable(lazy(() => import('../pages/dataEscalation')));
const Revenue = Loadable(lazy(() => import('../pages/revenue')));

const YogaProgram = Loadable(lazy(() => import('../pages/yogaProgram')));
const YogaProgramDetail = Loadable(lazy(() => import('../pages/yogaProgram/detail')));
const YogaProgramCreate = Loadable(lazy(() => import('../pages/yogaProgram/create')));

const CheckInBarcode = Loadable(lazy(() => import('../pages/checkIn/bacode')));

const Lounge = Loadable(lazy(() => import('../pages/lounge')));
const Week = Loadable(lazy(() => import('../pages/week')));
const WeekCreate = Loadable(lazy(() => import('../pages/week/create')));
const WeekUpdate = Loadable(lazy(() => import('../pages/week/update')));

const Item = Loadable(lazy(() => import('../pages/item')));
const ItemCreate = Loadable(lazy(() => import('../pages/item/create')));
const ItemUpdate = Loadable(lazy(() => import('../pages/item/update')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        { path: '/', element: <Schedule /> },

        { path: '/schedule', element: <Schedule /> },

        { path: '/reservation', element: <Reservation /> },
        { path: '/reservation/create', element: <CreateReservation /> },
        { path: '/reservation/:id', element: <DetailReservation /> },
        { path: '/check-in/history', element: <CheckInHistory /> },

        { path: '/yoga-class', element: <YogaClass /> },
        { path: '/yoga-class/create', element: <CreateYogaClass /> },
        { path: '/yoga-class/:id', element: <DetailYogaClass /> },
        { path: '/yoga-class/update/:id', element: <UpdateYogaClass /> },

        { path: '/yoga-lecture/:id', element: <YogaLectureDetail /> },
        { path: '/yoga-lecture/update/:id', element: <YogaLectureUpdate /> },

        { path: '/member', element: <Member /> },
        { path: '/member/:id', element: <MemberDetail /> },
        { path: '/member/create', element: <CreateMember /> },
        // { path: '/member/analysis', element: <MemberAnalysis /> },

        { path: '/teacher', element: <Teacher /> },
        { path: '/teacher/create', element: <CreateTeacher /> },
        { path: '/teacher/:id', element: <DetailTeacher /> },

        { path: '/ticket', element: <Ticket /> },
        { path: '/ticket/create', element: <CreateTicket /> },
        { path: '/ticket/:id', element: <TicketDetail /> },
        { path: '/ticket/update/:id', element: <UpdateTicket /> },

        { path: '/coupon', element: <Coupon /> },
        { path: '/coupon/create', element: <CreateCoupon /> },
        { path: '/coupon/:id', element: <UpdateCoupon /> },
        { path: '/yoga-coupon/:id', element: <UpdateYogaCoupon /> },

        { path: '/notification', element: <Notification /> },
        { path: '/notification/create', element: <CreateNotification /> },
        { path: '/notification/reservation', element: <NotificationReservation /> },
        { path: '/notification/temp', element: <NotificationTemp /> },
        { path: '/notification/push/:id', element: <DetailPushNotification /> },
        { path: '/notification/reservation/:id', element: <DetailNotificationReservcation /> },
        { path: '/notification/temp/:id', element: <DetailNotificationTemp /> },



        { path: '/setting/notice-popup', element: <NoticePopup /> },
        { path: '/setting/notice-popup/create', element: <CreateNoticePopup /> },
        { path: '/setting/notice-popup/:id', element: <DetailNoticePopup /> },

        { path: '/setting/banner', element: <Banner /> },
        { path: '/setting/banner/create', element: <BannerCreate /> },
        { path: '/setting/banner/:id', element: <BannerDetail /> },

        { path: '/setting/basic', element: <SettingBasic /> },
        { path: '/setting/detail', element: <SettingDetail /> },
        { path: '/setting/image-management', element: <ImageManagement /> },
        { path: '/setting/schedule', element: <SettingSchedule /> },
        { path: '/setting/event', element: <SettingEvent /> },
        { path: '/setting/event/create', element: <CreateEvent /> },
        { path: '/setting/event/:id', element: <DetailEvent /> },


        // { path: '/ticket-revenue', element: <TicketRevenue /> },
        // { path: '/ticket-revenue/analysis', element: <TicketRevenueAnalysis /> },

        { path: '/company', element: <Company /> },
        { path: '/company/detail/:id', element: <CompanyDetail /> },

        { path: '/data-escalation', element: <DataEscalation /> },
        { path: '/revenue', element: <Revenue /> },

        // { path: '/login', element: <Login /> },
        { path: '/yoga-program', element: <YogaProgram /> },
        { path: '/yoga-program/:id', element: <YogaProgramDetail /> },
        { path: '/yoga-program/create', element: <YogaProgramCreate /> },

        { path: '/check-in/barcode/:companyId/:yogaCenterId', element: <CheckInBarcode /> },

        { path: '/lounge', element: <Lounge /> },
        { path: '/week', element: <Week /> },
        { path: '/week/create', element: <WeekCreate /> },
        { path: '/week/:id', element: <WeekUpdate /> },

        { path: '/item', element: <Item /> },
        { path: '/item/create', element: <ItemCreate /> },
        { path: '/item/:id', element: <ItemUpdate /> },
    ],
};

export default MainRoutes;