import { AxiosError } from 'axios';
import BadRequest from './404';
import DeniedPage from './403';
import ServerError from './500';
import { Result } from 'antd';
interface IProps {
    errorData: AxiosError
}

const ErrorComponent = ({ errorData }: IProps) => {

    if (!errorData.response) {
        return <Result
            status="error"
            title="서버가 실행중이지 않습니다."
            subTitle="기술 문의는 플랫폼 개발팀에 해주세요"
        />
    }

    if (errorData.response.status === 404) {
        return <BadRequest />
    }

    if (errorData.response.status === 403) {
        return <DeniedPage />
    }

    if (errorData.response.status === 500) {
        return <ServerError />
    }

    return <Result
        status="error"
        title="Submission Failed"
        subTitle="Please check and modify the following information before resubmitting."
    />
}

export default ErrorComponent;