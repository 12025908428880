import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { MemberAction } from './types';
import { getCompanyMemberList } from '../../service/member'

import { getMemberListAsync } from './actions';

export function getMemberListThunk(companyId: number, searchValue: string = "", remainingCnt: string = "", remainingDate: string = ""): ThunkAction<void, RootState, null, MemberAction> {
    searchValue = searchValue ?? '';
    remainingCnt = remainingCnt ?? '';
    remainingDate = remainingDate ?? '';
    return async dispatch => {
        const { request, success, failure } = getMemberListAsync;
        dispatch(request());
        try {
            const memberList = await getCompanyMemberList(companyId, searchValue, remainingCnt, remainingDate);
            dispatch(success(memberList.data));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};